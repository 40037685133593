.loader-generic {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  color: #FFF;
  transform: translateY(-50%);
  z-index: 10000;
  visibility: visible;
}

.loader-generic img {
  width: 100px;
}

.loader-overlay{
  z-index: 10000;  
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 55%);
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

